export const FOR_NURSES_PAGE = '/fur-pflegekrafte';
export const FOR_PARTNERS_PAGE = '/fur-partner';
export const ERROR_PAGE = '/error';
export const LOGIN_PAGE = '/login';
export const SIGN_IN_PAGE = '/sign-in';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const EXTRA_PAGE = '/footer/:page';
export const HOME_PAGE = '/';
export const DASHBOARD_PAGE = '/dashboard';
export const TRAINING_PAGE = '/webinar';
export const VIDEO_PAGE = '/video/:id';
export const VIDEO_PAGE_EXPERT_TALKS = '/video/experten-talk/:id';
export const COACHING_PAGE = '/coaching';
export const CLIPS_PAGE = '/klips';

export const COACHING_MODULE_PAGE = '/coaching/module';
export const COACHING_LESSON_PAGE = '/coaching/lesson';
export const COACHING_MODULE_DETAILS_PAGE = '/coaching/module/:moduleId';
export const COACHING_MODULE_ENDED_PAGE = '/coaching/module/:moduleId/ended';
export const COACHING_DETAIL_PAGE = '/coaching/:coachId';
export const COACHING_LESSONS_PAGE = '/coaching/lesson/:lessonId';
export const COACHING_QUIZ_PAGE = '/quiz/:quizId';
export const CLIPS_MODULE_PAGE = '/klip/module';
export const CLIPS_MODULE_DETAILS_PAGE = '/klip/module/:moduleId';
export const CLIPS_MODULE_ENDED_PAGE = '/klip/module/:moduleId/ended';
export const CLIPS_DETAIL_PAGE = '/klips/:klipId';
export const CLIPS_LESSON_PAGE = '/klips/lesson';
export const CLIPS_LESSONS_PAGE = '/klips/lesson/:lessonId';
export const CLIPS_QUIZ_PAGE = '/quiz/:quizId';
export const EXPERT_TALKS_PAGE = '/experten-talk';
export const MY_PROFILE_PAGE = '/profil';
export const IMPRINT_PAGE = '/footer/impressum';
export const COACHING_PAYMENT_STEP = '/coachings/payment/step';
export const COACHING_PAYMENT_FIRST_STEP = '/coachings/payment/step/1/:coachId';
export const COACHING_PAYMENT_SECOND_STEP = '/coachings/payment/step/2/:coachId';
export const COACHING_PAYMENT_THIRD_STEP = '/coachings/payment/step/3/:coachId';
export const COACHING_PAYMENT_FOURTH_STEP = '/purchase-success';
export const CONTACT_PAGE = '/footer/contact';
export const QUIZ_PAGE = '/quiz/:quizId';
export const COOKIE_PAGE = '/footer/cookie';
export const LEGAL_NOTICE_PAGE = '/footer/rechtliche';
export const PRIVACY_POLICY_PAGE = '/footer/datenschutzerklarung';
export const REGISTER_PAGE = '/register';
export const REGISTER_PAGE_CODE = '/register/code/:coachingCode';
export const REGISTRATION_CONFIRMATION_PAGE = '/register-confirm';
export const REGISTRATION_CONFIRMED_PAGE = '/register-confirmed';
export const NEWSLETTER_CONFIRM_PAGE = '/newsletter-bestaetigung';
export const NEWSLETTER_CONFIRMED_PAGE = '/newsletter-bestaetigt';
export const MODULE_PAYMENT_FIRST_STEP = '/coachings/module/payment/step/1/:moduleId';
export const MODULE_PAYMENT_SECOND_STEP = '/coachings/module/payment/step/2/:moduleId';
export const MODULE_PAYMENT_THIRD_STEP = '/coachings/module/payment/step/3/:moduleId';
export const MODULE_PAYMENT_FOURTH_STEP = '/purchase-success';
export const FULL_CONTACT_PAGE = '/contact';

export const PARTNERS_PAGE = '/partner';
export const PARTNER_PAGE_SINGULAR = '/partner/:id';
export const PARTNERS_PAGE_REDIRECT = '/partners';

// Endpoints
export const REGISTER_ENDPOINT = '/auth/register';
export const SIGN_IN_ENDPOINT = '/auth/token';
export const FORGOT_PASSWORD_ENDPOINT = '/auth/forgot-password';
export const RESET_PASSWORD_ENDPOINT = '/auth/reset-password';
export const LOGOUT_ENDPOINT = '/auth/logout';
export const CONTACT_FORM_ENDPOINT = '/contact';
export const USER_CREDENTIALS = '/user/credentials';
export const USER_PROFILE = '/user/profile';
export const DESTROY_PROFILE = '/user/delete-profile';
export const USER_PROFILE_AVATAR = '/user/profile/avatar';
export const COMPANY_LOGOS_ENDPOINT = '/company/logos';
export const NEXT_EXPERT_TALKS_ENDPOINT = '/video/upcoming/expert-talks';
export const PREVIOUS_EXPERT_TALKS_ENDPOINT = '/video/past/expert-talks';
export const MY_COURSES = '/course/mine';
export const MY_COACHINGS = '/coaching/mine';
export const MY_CLIPS = '/klips/mine';
export const VIDEO_EXPERT_ASK_ENDPOINT = '/video/ask-question';
export const COURSE_VIDEOS_ENDPOINT = '/course/videos';
export const COURSE_FILTERS_ENDPOINT = '/course/filters';
export const COURSE_DATES_ENDPOINT = '/course/:id/dates';
export const COURSE_DATES_BOOK_ENDPOINT = '/course/date/:dateId/book';
export const COURSE_DATES_RESERVE_ENDPOINT = '/course/date/:dateId/book-for-waiting-list';
export const COURSE_DATES_INTERESTED_IN_ENDPOINT = '/course/:courseId/interested-in';
export const VIDEO_ENDPOINT = '/video/watch/:id';
export const VIDEO_EXPERT_TALKS_ENDPOINT = '/video/watch/expert-talk/:id';
export const COACH_EXPERT_ASK_ENDPOINT = '/coaching/:coachId/ask-the-coach';
export const CLIP_EXPERT_ASK_ENDPOINT = '/klips/:klipId/ask-the-klip-creator';
export const VIDEO_FILE_DOWNLOAD = '/video/file/download';
export const COACHES_ALL = '/coaching/all';
export const COACHES_BOOKABLE_DETAIL = '/coaching/bookable/:coachId';
export const COACHES_BOOKABLE_MODULE_DETAIL = '/coaching/bookable-module/:moduleId';
export const COACHES_BOOKABLE_BY_CODE = '/coaching/:coachingId/book-with-code';
export const MODULE_BOOKABLE_BY_CODE = '/coaching/module/:moduleId/book-with-code';
export const CLIPS_ALL = '/klips/all';
export const CLIPS_BOOKABLE_DETAIL = '/klips/:klipId';
export const NOTE_CREATE_ENDPOINT = '/note/create';
export const INTERESTED_IN_ENDPOINT = '/dashboard/interested-in';
export const CURRENT_VIDEO_ENDPOINT = '/dashboard/current';
export const PREVIOUSLY_WATCHED_ENDPOINT = '/dashboard/previously-watched';
export const PURCHASED_COACHING_ENDPOINT = '/dashboard/coachings';
export const PURCHASED_CLIPS_ENDPOINT = '/dashboard/klips';
export const HOME_PAGE_ENDPOINT = '/home-page';
export const FOR_NURSES_ENDPOINT = '/for-nurses';
export const FOR_PARTNERS_ENDPOINT = '/for-partners';
export const COMPANIES_ENDPOINT = '/course/company';
export const COMPANY_ENDPOINT = '/course/company/:id';
export const COMPANY_DETAIL_ENDPOINT = 'companies/:id';
export const EXTRA_ENDPOINT = '/footer';
export const NEWSLETTER_ENDPOINT = '/newsletter/subscribe';
export const TERMINATE_COURSE_BOOKING_ENDPOINT = '/course/:id/terminate/appointment';
export const TERMINATE_WAITING_LIST_BOOKING_ENDPOINT = '/course/:id/terminate/waiting-list-booking';
export const GET_COMPRESSED_FILE_ENDPOINT = '/course/:id/files/download-all';
export const COACHING_MODULES_ENDPOINT = '/coaching/booked/:id';
export const COACHING_LESSONS_ENDPOINT = '/video/coaching/module/watch/:id';
export const COACHING_VIDEO_PROGRESS_ENDPOINT = 'video/:id/progress';
export const COACHING_NOTE_CREATE_ENDPOINT = '/note/lesson/create';
export const COACHING_BOOK_UPCOMING_TUTORIUM = '/coaching/tutorium/:tutoriumId/book';
export const CLIPS_MODULES_ENDPOINT = '/klips/booked/:id';
export const CLIPS_LESSONS_ENDPOINT = '/video/klip/module/watch/:id';
export const CLIPS_VIDEO_PROGRESS_ENDPOINT = 'video/klip/:id/progress';
export const CLIPS_NOTE_CREATE_ENDPOINT = '/note/lesson/create';
export const REQUEST_ACCESS_TO_COURSE_ENDPOINT = '/course/:courseId/request-access';
export const QUIZZES_ENDPOINT = '/quiz/:quizId';
export const SUBMIT_QUIZ_ANSWER = '/quiz/:quizId/question/:questionId/submit/answers';
export const RETAKE_QUIZ = '/quiz/:quizId/retake';
export const DELETE_QUIZ_PROGRESS = '/quiz/:quizId/delete-progress';
export const OVERVIEW_PAGES = '/presentation-pages';
export const PAYPAL_PURCHASE = '/paypal-purchase';
export const PAYPAL_INVOICE = '/paypal-invoice';
export const PAYPAL_MODULE_INVOICE = '/paypal-module-invoice';
export const PAYPAL_MODULE_PURCHASE = '/paypal-module-purchase';
export const PARTNERS_ENDPOINT = '/companies/partners';
export const SOCIAL_MEDIA_ENDPOINT = '/social-links';
